import axios from 'axios'
import api from '../api/index'

const GET_ALL_SUBSCRIPTIONS_REQUEST = 'ASTAEXPERT/GET_ALL_SUBSCRIPTIONS_REQUEST'
const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'ASTAEXPERT/GET_ALL_SUBSCRIPTIONS_SUCCESS'
const GET_ALL_SUBSCRIPTIONS_FAILURE = 'ASTAEXPERT/GET_ALL_SUBSCRIPTIONS_FAILURE'

const GET_SUBSCRIPTION_BY_ID_REQUEST = 'ASTAEXPERT/GET_SUBSCRIPTION_BY_ID_REQUEST'
const GET_SUBSCRIPTION_BY_ID_SUCCESS = 'ASTAEXPERT/GET_SUBSCRIPTION_BY_ID_SUCCESS'
const GET_SUBSCRIPTION_BY_ID_FAILURE = 'ASTAEXPERT/GET_SUBSCRIPTION_BY_ID_FAILURE'

const CREATE_SUBSCRIPTION_REQUEST = 'ASTAEXPERT/CREATE_SUBSCRIPTION_REQUEST'
const CREATE_SUBSCRIPTION_SUCCESS = 'ASTAEXPERT/CREATE_SUBSCRIPTION_SUCCESS'
const CREATE_SUBSCRIPTION_FAILURE = 'ASTAEXPERT/CREATE_SUBSCRIPTION_FAILURE'

const UPDATE_SUBSCRIPTION_REQUEST = 'ASTAEXPERT/UPDATE_SUBSCRIPTION_REQUEST'
const UPDATE_SUBSCRIPTION_SUCCESS = 'ASTAEXPERT/UPDATE_SUBSCRIPTION_SUCCESS'
const UPDATE_SUBSCRIPTION_FAILURE = 'ASTAEXPERT/UPDATE_SUBSCRIPTION_FAILURE'

const DELETE_SUBSCRIPTION_REQUEST = 'ASTAEXPERT/DELETE_SUBSCRIPTION_REQUEST'
const DELETE_SUBSCRIPTION_SUCCESS = 'ASTAEXPERT/DELETE_SUBSCRIPTION_SUCCESS'
const DELETE_SUBSCRIPTION_FAILURE = 'ASTAEXPERT/DELETE_SUBSCRIPTION_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleSubscription: '',
}

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTIONS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_SUBSCRIPTIONS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_SUBSCRIPTION_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_SUBSCRIPTION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSubscription: action.payload,
      }
    case GET_SUBSCRIPTION_BY_ID_FAILURE:
      return { ...state, loading: false, singleSubscription: '', error: action.payload }
    case CREATE_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      }
    case CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSubscription: action.payload,
      }
    case UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((sub) => sub.id != action.payload),
      }
    case DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllSubscriptions = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_SUBSCRIPTIONS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllSubscriptions()
      const subscriptions = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
        payload: subscriptions.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_SUBSCRIPTIONS_FAILURE,
        payload: error,
      })
    }
  }
}

export const getSubscriptionById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SUBSCRIPTION_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getSubscriptionById(id)
      const subscriptions = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_SUBSCRIPTION_BY_ID_SUCCESS,
        payload: subscriptions.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_SUBSCRIPTION_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createSubscription = (subscriptionData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createSubscription(subscriptionData)
      const createdSubscription = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: createdSubscription.data,
      })
    } catch (error) {
      console.log(error)
      console.log(error.message)
      dispatch({
        type: CREATE_SUBSCRIPTION_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateSubscription = (id, subscriptionUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIPTION_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateSubscription(id, subscriptionUpdates)

      const updatedSubscription = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_SUBSCRIPTION_SUCCESS,
        payload: updatedSubscription.data,
      })
      return 'updated'
    } catch (error) {
      console.log(error)
      console.log(error.message)
      dispatch({
        type: UPDATE_SUBSCRIPTION_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteSubscription = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_SUBSCRIPTION_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteSubscription(id)

      const deletedSubscription = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: DELETE_SUBSCRIPTION_SUCCESS,
        payload: deletedSubscription.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_SUBSCRIPTION_FAILURE,
        payload: error,
      })
    }
  }
}

import axios from 'axios'
import api from '../api/index'

const GET_ALL_PAGES_REQUEST = 'ASTAEXPERT/GET_ALL_PAGES_REQUEST'
const GET_ALL_PAGES_SUCCESS = 'ASTAEXPERT/GET_ALL_PAGES_SUCCESS'
const GET_ALL_PAGES_FAILURE = 'ASTAEXPERT/GET_ALL_PAGES_FAILURE'

const GET_ALL_PAGE_ELEMENTS_REQUEST = 'ASTAEXPERT/GET_ALL_PAGE_ELEMENTS_REQUEST'
const GET_ALL_PAGE_ELEMENTS_SUCCESS = 'ASTAEXPERT/GET_ALL_PAGE_ELEMENTS_SUCCESS'
const GET_ALL_PAGE_ELEMENTS_FAILURE = 'ASTAEXPERT/GET_ALL_PAGE_ELEMENTS_FAILURE'

const UPDATE_PAGE_ELEMENT_REQUEST = 'ASTAEXPERT/UPDATE_PAGE_ELEMENT_REQUEST'
const UPDATE_PAGE_ELEMENT_SUCCESS = 'ASTAEXPERT/UPDATE_PAGE_ELEMENT_SUCCESS'
const UPDATE_PAGE_ELEMENT_FAILURE = 'ASTAEXPERT/UPDATE_PAGE_ELEMENT_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  pageElements: null,
}

export default function reducerPages(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PAGES_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_PAGES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_ALL_PAGE_ELEMENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_PAGE_ELEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pageElements: action.payload,
      }
    case GET_ALL_PAGE_ELEMENTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case UPDATE_PAGE_ELEMENT_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_PAGE_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
        pageElements: action.payload,
      }
    case UPDATE_PAGE_ELEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllPages = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_PAGES_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllPages()

      const pages = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_ALL_PAGES_SUCCESS,
        payload: pages.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_PAGES_FAILURE,
        payload: error,
      })
    }
  }
}

export const getPageElements = (pageId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_PAGE_ELEMENTS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getPageElements(pageId)
      const pageElements = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_ALL_PAGE_ELEMENTS_SUCCESS,
        payload: pageElements.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_PAGE_ELEMENTS_FAILURE,
        payload: error,
      })
    }
  }
}

export const updatePageElements = (pageId, contentUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PAGE_ELEMENT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updatePageElements(pageId, contentUpdates)

      const updatedContent = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_PAGE_ELEMENT_SUCCESS,
        payload: updatedContent.data,
      })
      return 'updated'
    } catch (error) {
      console.log(error)
      dispatch({
        type: UPDATE_PAGE_ELEMENT_FAILURE,
        payload: error,
      })
    }
  }
}

import axios from 'axios'
import api from '../api/index'

const GET_ALL_REALESTATES_REQUEST = 'ASTAEXPERT/GET_ALL_REALESTATES_REQUEST'
const GET_ALL_REALESTATES_SUCCESS = 'ASTAEXPERT/GET_ALL_REALESTATES_SUCCESS'
const GET_ALL_REALESTATES_FAILURE = 'ASTAEXPERT/GET_ALL_REALESTATES_FAILURE'

const CREATE_REALESTATE_REQUEST = 'ASTAEXPERT/CREATE_REALESTATE_REQUEST'
const CREATE_REALESTATE_SUCCESS = 'ASTAEXPERT/CREATE_REALESTATE_SUCCESS'
const CREATE_REALESTATE_FAILURE = 'ASTAEXPERT/CREATE_REALESTATE_FAILURE'

const GET_REALESTATE_BY_ID_REQUEST = 'ASTAEXPERT/GET_REALESTATE_BY_ID_REQUEST'
const GET_REALESTATE_BY_ID_SUCCESS = 'ASTAEXPERT/GET_REALESTATE_BY_ID_SUCCESS'
const GET_REALESTATE_BY_ID_FAILURE = 'ASTAEXPERT/GET_REALESTATE_BY_ID_FAILURE'

const UPDATE_REALESTATE_REQUEST = 'ASTAEXPERT/UPDATE_REALESTATE_REQUEST'
const UPDATE_REALESTATE_SUCCESS = 'ASTAEXPERT/UPDATE_REALESTATE_SUCCESS'
const UPDATE_REALESTATE_FAILURE = 'ASTAEXPERT/UPDATE_REALESTATE_FAILURE'

const DELETE_REALESTATE_REQUEST = 'ASTAEXPERT/DELETE_REALESTATE_REQUEST'
const DELETE_REALESTATE_SUCCESS = 'ASTAEXPERT/DELETE_REALESTATE_SUCCESS'
const DELETE_REALESTATE_FAILURE = 'ASTAEXPERT/DELETE_REALESTATE_FAILURE'

const initialState = {
  loading: false,
  loadingSingle: false,
  error: false,
  data: { realEstates: [], pagination: {} },
  singleRealEstate: {},
}

export default function reducerRealEstates(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REALESTATES_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_REALESTATES_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_REALESTATES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case CREATE_REALESTATE_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_REALESTATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, realEstate: [action.payload, ...state.data.realEstates] },
      }
    case CREATE_REALESTATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_REALESTATE_BY_ID_REQUEST:
      return { ...state, loadingSingle: true, error: false }
    case GET_REALESTATE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleRealEstate: action.payload,
      }
    case GET_REALESTATE_BY_ID_FAILURE:
      return { ...state, loadingSingle: false, singleRealEstate: {}, error: action.payload }

    case UPDATE_REALESTATE_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_REALESTATE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleRealEstate: action.payload,
      }
    case UPDATE_REALESTATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_REALESTATE_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_REALESTATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((realEstate) => realEstate.id !== action.payload),
      }
    case DELETE_REALESTATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllRealEstates = (pageStart) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_REALESTATES_REQUEST,
    })

    try {
      const { url, method, headers, params } = api.getAllRealEstates(pageStart)

      // console.log("params", params);

      const realEstates = await axios({
        url,
        method,
        headers,
        params,
      })

      console.log('RESPONSE', realEstates.data)

      dispatch({
        type: GET_ALL_REALESTATES_SUCCESS,
        payload: realEstates.data,
      })
      return realEstates.data
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_REALESTATES_FAILURE,
        payload: error,
      })
    }
  }
}
export const getAllRealEstatesByAgent = (id, pageStart) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_REALESTATES_REQUEST,
    })

    try {
      const { url, method, headers, params } = api.getAllRealEstatesByAgent(id, pageStart)

      // console.log("params", params);

      const realEstates = await axios({
        url,
        method,
        headers,
        params,
      })

      console.log('RESPONSE', realEstates.data)

      dispatch({
        type: GET_ALL_REALESTATES_SUCCESS,
        payload: realEstates.data,
      })
      return realEstates.data
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_REALESTATES_FAILURE,
        payload: error,
      })
    }
  }
}

export const getRealEstateById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_REALESTATE_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getRealEstateById(id)

      // console.log("params", params);

      const realEstate = await axios({
        url,
        method,
        headers,
      })

      console.log('RESPONSE', realEstate.data)

      dispatch({
        type: GET_REALESTATE_BY_ID_SUCCESS,
        payload: realEstate.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_REALESTATE_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createRealEstate = (realEstateData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_REALESTATE_REQUEST,
    })
    try {
      const auctionData = Object.entries(realEstateData)
        .filter(([key, value]) => !!key.includes('auction.'))
        .reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key.split('auction.').join('')]: value,
          }
        }, {})

      realEstateData = Object.entries(realEstateData)
        .filter(([key, value]) => !key.includes('auction.'))
        .reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: value,
          }
        }, {})

      const { url, method, headers, data } = api.createRealEstate({
        ...realEstateData,
        // auction: Object.entries(auctionData).length > 0 ? auctionData : null,
        auction: auctionData,
      })

      const createdRealEstate = await axios({
        url,
        method,
        headers,
        data,
      })
      // console.log('CREATED', createdRealEstate.data)
      dispatch({
        type: CREATE_REALESTATE_SUCCESS,
        payload: createdRealEstate.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_REALESTATE_FAILURE,
        payload: error,
      })
    }
  }
}

export const createRealEstateWithImport = (importFile) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_REALESTATE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createRealEstateWithImport(importFile)

      const createdRealEstate = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_REALESTATE_SUCCESS,
        payload: createdRealEstate.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_REALESTATE_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateRealEstate = (id, realEstateUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_REALESTATE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateRealEstate(id, realEstateUpdates)

      const updatedRealEstate = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_REALESTATE_SUCCESS,
        payload: updatedRealEstate.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_REALESTATE_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteRealEstate = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_REALESTATE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteRealEstate(id)

      const deletedRealEstate = await axios({
        url,
        method,
        headers,
        data,
      })
      if (deletedRealEstate.data) {
        dispatch({
          type: DELETE_REALESTATE_SUCCESS,
          payload: id,
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_REALESTATE_FAILURE,
        payload: error,
      })
    }
  }
}

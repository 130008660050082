import axios from 'axios'
import api from '../api/index'

const GET_ALL_ARTICLES_REQUEST = 'ASTAEXPERT/GET_ALL_ARTICLES_REQUEST'
const GET_ALL_ARTICLES_SUCCESS = 'ASTAEXPERT/GET_ALL_ARTICLES_SUCCESS'
const GET_ALL_ARTICLES_FAILURE = 'ASTAEXPERT/GET_ALL_ARTICLES_FAILURE'

const GET_SINGLE_ARTICLE_REQUEST = 'ASTAEXPERT/GET_SINGLE_ARTICLE_REQUEST'
const GET_SINGLE_ARTICLE_SUCCESS = 'ASTAEXPERT/GET_SINGLE_ARTICLE_SUCCESS'
const GET_SINGLE_ARTICLE_FAILURE = 'ASTAEXPERT/GET_SINGLE_ARTICLE_FAILURE'

const CREATE_ARTICLE_REQUEST = 'ASTAEXPERT/CREATE_ARTICLE_REQUEST'
const CREATE_ARTICLE_SUCCESS = 'ASTAEXPERT/CREATE_ARTICLE_SUCCESS'
const CREATE_ARTICLE_FAILURE = 'ASTAEXPERT/CREATE_ARTICLE_FAILURE'

const UPDATE_ARTICLE_REQUEST = 'ASTAEXPERT/UPDATE_ARTICLE_REQUEST'
const UPDATE_ARTICLE_SUCCESS = 'ASTAEXPERT/UPDATE_ARTICLE_SUCCESS'
const UPDATE_ARTICLE_FAILURE = 'ASTAEXPERT/UPDATE_ARTICLE_FAILURE'

const DELETE_ARTICLE_REQUEST = 'ASTAEXPERT/DELETE_ARTICLE_REQUEST'
const DELETE_ARTICLE_SUCCESS = 'ASTAEXPERT/DELETE_ARTICLE_SUCCESS'
const DELETE_ARTICLE_FAILURE = 'ASTAEXPERT/DELETE_ARTICLE_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singlePost: '',
}

export default function reducerArticles(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ARTICLES_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_ARTICLES_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_ARTICLES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case GET_SINGLE_ARTICLE_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_SINGLE_ARTICLE_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singlePost: action.payload,
      }
    case GET_SINGLE_ARTICLE_FAILURE:
      return { ...state, loading: false, singlePost: '', error: action.payload }

    case CREATE_ARTICLE_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleArticles: action.payload,
      }
    case CREATE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_ARTICLE_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((article) => article.id !== action.payload),
      }
    case DELETE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const getAllArticles = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_ARTICLES_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllArticles()

      // console.log("params", params);

      const articles = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_ALL_ARTICLES_SUCCESS,
        payload: articles.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_ARTICLES_FAILURE,
        payload: error,
      })
    }
  }
}

export const getSingleArticle = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SINGLE_ARTICLE_REQUEST,
    })
    try {
      const { url, method, headers } = api.getSingleArticle(id)

      const singleArticle = await axios({
        url,
        method,
        headers,
      })
      dispatch({
        type: GET_SINGLE_ARTICLE_SUCCESS,
        payload: singleArticle.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_SINGLE_ARTICLE_FAILURE,
        payload: error,
      })
    }
  }
}
export const createArticle = (articleData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ARTICLE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createArticle(articleData)
      console.log('data', data)
      const createdArticle = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_ARTICLE_SUCCESS,
        payload: createdArticle.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_ARTICLE_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateArticle = (id, articleUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ARTICLE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateArticle(id, articleUpdates)

      const updatedArticle = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_ARTICLE_SUCCESS,
        payload: updatedArticle.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_ARTICLE_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteArticle = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ARTICLE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteArticle(id)

      const deletedArticle = await axios({
        url,
        method,
        headers,
        data,
      })
      if (deletedArticle.data) {
        dispatch({
          type: DELETE_ARTICLE_SUCCESS,
          payload: id,
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_ARTICLE_FAILURE,
        payload: error,
      })
    }
  }
}

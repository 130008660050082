import { combineReducers } from 'redux'

import accounts from './accounts'
import users from './users'
import articles from './articles'
import faqs from './faqs'
import auctions from './auctions'
import courthouses from './courthouses'
import forms from './forms'
import subscriptions from './subscriptions'
import visits from './visits'
import contacts from './contacts'
import realEstates from './realEstates'
import businessClients from './businessClients'
import auctionRequests from './auctionRequests'
import sidebarStatus from './sidebarStatus'
import cities from './cities'
import notifications from './notifications'
import coupons from './coupons'
import pages from './pages'

export default combineReducers({
  articles,
  faqs,
  courthouses,
  forms,
  subscriptions,
  visits,
  contacts,
  users,
  realEstates,
  businessClients,
  auctions,
  accounts,
  auctionRequests,
  sidebarStatus,
  cities,
  notifications,
  coupons,
  pages,
})

import axios from 'axios'
import api from '../api/index'

const LOGIN_ACCOUNT_SUCCESS = 'ASTAEXPERT/LOGIN_ACCOUNT_SUCCESS'
const LOGIN_ACCOUNT_REQUEST = 'ASTAEXPERT/LOGIN_ACCOUNT_REQUEST'
const LOGIN_ACCOUNT_FAILURE = 'ASTAEXPERT/LOGIN_ACCOUNT_FAILURE'

const LOGOUT_ACCOUNT = 'ASTAEXPERT/LOGOUT_ACCOUNT'

// const SIGN_UP_ACCOUNT_SUCCESS = 'ASTAEXPERT/SIGN_UP_ACCOUNT_SUCCESS'
// const SIGN_UP_ACCOUNT_REQUEST = 'ASTAEXPERT/SIGN_UP_ACCOUNT_REQUEST'
// const SIGN_UP_ACCOUNT_FAILURE = 'ASTAEXPERT/SIGN_UP_ACCOUNT_FAILURE'

// const GET_ACCOUNT_REQUEST = 'ASTAEXPERT/GET_ACCOUNT_REQUEST'
// const GET_ACCOUNT_SUCCESS = 'ASTAEXPERT/GET_ACCOUNT_SUCCESS'
// const GET_ACCOUNT_FAILURE = 'ASTAEXPERT/GET_ACCOUNT_FAILURE'

// const UPDATE_ACCOUNT_REQUEST = 'ASTAEXPERT/UPDATE_ACCOUNT_REQUEST'
// const UPDATE_ACCOUNT_SUCCESS = 'ASTAEXPERT/UPDATE_ACCOUNT_SUCCESS'
// const UPDATE_ACCOUNT_FAILURE = 'ASTAEXPERT/UPDATE_ACCOUNT_FAILURE'

// const DELETE_ACCOUNT_REQUEST = 'ASTAEXPERT/DELETE_ACCOUNT_REQUEST'
// const DELETE_ACCOUNT_SUCCESS = 'ASTAEXPERT/DELETE_ACCOUNT_SUCCESS'
// const DELETE_ACCOUNT_FAILURE = 'ASTAEXPERT/DELETE_ACCOUNT_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleAccount: '',
}

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case LOGIN_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: false }
    case LOGIN_ACCOUNT_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case LOGIN_ACCOUNT_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case LOGOUT_ACCOUNT:
      return { ...initialState }

    // case SIGN_UP_ACCOUNT_REQUEST:
    //   return { ...state, loading: true, error: false }
    // case SIGN_UP_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     // singleUser: action.payload,
    //   }
    // case SIGN_UP_ACCOUNT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   }
    // case GET_ACCOUNT_REQUEST:
    //   return { ...state, loading: true, error: false }
    // case GET_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     singleAccount: action.payload,
    //   }
    // case GET_ACCOUNT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   }
    // case UPDATE_ACCOUNT_REQUEST:
    //   return { ...state, loading: true, error: false }
    // case UPDATE_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     singleAccount: action.payload,
    //   }
    // case UPDATE_ACCOUNT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   }

    // case DELETE_ACCOUNT_REQUEST:
    //   return { ...state, loading: true, error: false }
    // case DELETE_ACCOUNT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     // singleAccount: action.payload,
    //   }
    // case DELETE_ACCOUNT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   }

    default:
      return state
  }
}

// export const login = (credentials) => {
//   return async (dispatch) => {
//     dispatch({
//       type: LOGIN_ACCOUNT_REQUEST,
//     })

//     try {
//       const { url, method, headers, data } = api.login(credentials)

//       const account = await axios({
//         url,
//         method,
//         headers,
//         data,
//       })
//       console.log('login')
//       dispatch({
//         type: LOGIN_ACCOUNT_SUCCESS,
//         payload: account.data,
//       })
//     } catch (error) {
//       console.log('error', error)
//       console.log('error', error.response)

//       dispatch({
//         type: LOGIN_ACCOUNT_FAILURE,
//         payload: error,
//       })
//     }
//   }
// }

export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_ACCOUNT_REQUEST,
    })
    console.log('dispached', credentials)

    try {
      if (!credentials) {
        const userLs = localStorage.getItem('account')
        if (userLs) {
          const account = JSON.parse(userLs)
          dispatch({
            type: LOGIN_ACCOUNT_SUCCESS,
            payload: account,
          })
        } else {
          // console.log("OKOK");
          dispatch({
            type: LOGIN_ACCOUNT_FAILURE,
            payload: 'no_account_in_local_storage',
          })
        }
      } else {
        const { url, method, headers, data } = api.login(credentials)

        const account = await axios({
          url,
          method,
          headers,
          data,
        })
        console.log('qui', account.data)

        if (account) {
          dispatch({
            type: LOGIN_ACCOUNT_SUCCESS,
            payload: account.data,
          })

          localStorage.setItem('account', JSON.stringify(account.data))
        } else {
          dispatch({
            type: LOGIN_ACCOUNT_FAILURE,
            payload: 'no_account_found',
          })
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'account_not_approved'
      ) {
        dispatch({
          type: LOGIN_ACCOUNT_FAILURE,
          payload: 'account_not_approved',
        })
      } else {
        dispatch({
          type: LOGIN_ACCOUNT_FAILURE,
          payload: 'error_login',
        })
      }
    }
  }
}

export const logout = () => {
  // return async (dispatch) => {
  //   dispatch({
  //     type: LOGOUT_ACCOUNT,
  //   })
  localStorage.clear()
}

// export const signUp = (userData) => {
//   return async (dispatch) => {
//     dispatch({
//       type: SIGN_UP_ACCOUNT_REQUEST,
//     })
//     try {
//       const { url, method, headers, data } = api.signUp(userData)

//       const updatedAccount = await axios({
//         url,
//         method,
//         headers,
//         data,
//       })
//       dispatch({
//         type: SIGN_UP_ACCOUNT_SUCCESS,
//         payload: updatedAccount.data,
//       })
//     } catch (error) {
//       // console.log(error);
//       dispatch({
//         type: SIGN_UP_ACCOUNT_FAILURE,
//         payload: error,
//       })
//     }
//   }
// }

// export const updateUser = (id, userUpdates) => {
//   return async (dispatch) => {
//     dispatch({
//       type: UPDATE_USER_REQUEST,
//     })
//     try {
//       const { url, method, headers, data } = api.updateUser(id, userUpdates)

//       const updatedUser = await axios({
//         url,
//         method,
//         headers,
//         data,
//       })
//       dispatch({
//         type: UPDATE_USER_SUCCESS,
//         payload: updatedUser.data,
//       })
//     } catch (error) {
//       // console.log(error);
//       dispatch({
//         type: UPDATE_USER_FAILURE,
//         payload: error,
//       })
//     }
//   }
// }

// export const deleteUser = (id) => {
//   return async (dispatch) => {
//     dispatch({
//       type: DELETE_USER_REQUEST,
//     })
//     try {
//       const { url, method, headers, data } = api.deleteUser(id)

//       const deletedUser = await axios({
//         url,
//         method,
//         headers,
//         data,
//       })
//       dispatch({
//         type: DELETE_USER_SUCCESS,
//         payload: deletedUser.data,
//       })
//     } catch (error) {
//       // console.log(error);
//       dispatch({
//         type: DELETE_USER_FAILURE,
//         payload: error,
//       })
//     }
//   }
// }

import axios from 'axios'
import api from '../api/index'

const GET_COUPON_REQUEST = 'ASTAEXPERT/COUPON/GET_COUPON_REQUEST'
const GET_COUPON_SUCCESS = 'ASTAEXPERT/COUPON/GET_COUPON_SUCCESS'
const GET_COUPON_FAILURE = 'ASTAEXPERT/COUPON/GET_COUPON_FAILURE'

const initialState = {
  loading: false,
  error: false,
  coupon: null,
}

export default function reducerCoupons(state = initialState, action) {
  switch (action.type) {
    case GET_COUPON_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload,
      }
    case GET_COUPON_FAILURE:
      return { ...state, loading: false, coupon: null, error: action.payload }
    default:
      return state
  }
}

export const getCoupon = (code) => {
  return async (dispatch) => {
    dispatch({
      type: GET_COUPON_REQUEST,
    })

    try {
      const { url, method, headers } = api.getCoupon(code)

      const res = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_COUPON_SUCCESS,
        payload: res.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_COUPON_FAILURE,
        payload: error,
      })
    }
  }
}

import { api, baseUrl } from '../config/server'

const API_ROOT_URL = api + baseUrl

const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return headers
}

const getAuthHeaders = () => {
  const userAS = localStorage.getItem('account')
  let token = ''

  if (userAS) {
    const user = JSON.parse(userAS)
    token = user.authToken
  }

  return {
    ...getHeaders(),
    Authorization: 'Bearer ' + token,
  }
}

const requests = {
  // SESSIONS
  login: (credentials) => {
    return {
      url: `${API_ROOT_URL}/account`,
      method: 'POST',
      headers: getHeaders(),
      data: credentials,
    }
  },
  signUp: (newUserData) => {
    return {
      url: `${API_ROOT_URL}/account/signup`,
      method: 'POST',
      headers: getHeaders(),
      data: newUserData,
    }
  },
  // FAQS
  getAllFaqs: () => {
    return {
      url: `${API_ROOT_URL}/faq`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getFaqById: (id) => {
    return {
      url: `${API_ROOT_URL}/faq/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createFaq: (data) => {
    return {
      url: `${API_ROOT_URL}/faq`,
      method: 'POST',
      headers: getAuthHeaders(),
      data: data,
    }
  },
  updateFaq: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/faq/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteFaq: (id) => {
    return {
      url: `${API_ROOT_URL}/faq/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  getAllCategories: () => {
    return {
      url: `${API_ROOT_URL}/faq/categories`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createFaqCategory: (data) => {
    return {
      url: `${API_ROOT_URL}/faq/categories`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateFaqCategory: (id, data) => {
    return {
      url: `${API_ROOT_URL}/faq/categories/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data,
    }
  },
  deleteFaqCategory: (id) => {
    return {
      url: `${API_ROOT_URL}/faq/categories/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  // COURTHOUSE
  getAllCourthouses: () => {
    return {
      url: `${API_ROOT_URL}/courthouse`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getCourthouseById: (id) => {
    return {
      url: `${API_ROOT_URL}/courthouse/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createCourthouse: (data) => {
    return {
      url: `${API_ROOT_URL}/courthouse`,
      method: 'POST',
      headers: getAuthHeaders(),
      data: data,
    }
  },
  updateCourthouse: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/courthouse/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteCourthouse: (id) => {
    return {
      url: `${API_ROOT_URL}/courthouse/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },

  // ARTICLES

  getAllArticles: () => {
    return {
      url: `${API_ROOT_URL}/post`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getSingleArticle: (id) => {
    return {
      url: `${API_ROOT_URL}/post/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createArticle: (data) => {
    return {
      url: `${API_ROOT_URL}/post`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateArticle: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/post/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteArticle: (id) => {
    return {
      url: `${API_ROOT_URL}/post/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  // FORMS
  getAllForms: () => {
    return {
      url: `${API_ROOT_URL}/form`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getFormById: (id) => {
    return {
      url: `${API_ROOT_URL}/form/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createForm: (data) => {
    return {
      url: `${API_ROOT_URL}/form`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateForm: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/form/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteForm: (id) => {
    return {
      url: `${API_ROOT_URL}/form/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  // SUBSCRIPTIONS
  getAllSubscriptions: () => {
    return {
      url: `${API_ROOT_URL}/subscription`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getSubscriptionById: (id) => {
    return {
      url: `${API_ROOT_URL}/subscription/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createSubscription: (data) => {
    return {
      url: `${API_ROOT_URL}/subscription`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateSubscription: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/subscription/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteSubscription: (id) => {
    return {
      url: `${API_ROOT_URL}/subscription/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  sendSubscriptionLink: (data) => {
    return {
      url: `${API_ROOT_URL}/subscription/send-subscription-link`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },

  // VISITS

  getAllVisits: () => {
    return {
      url: `${API_ROOT_URL}/visit`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  getVisitById: (id) => {
    return {
      url: `${API_ROOT_URL}/visit/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createVisit: (data) => {
    return {
      url: `${API_ROOT_URL}/visit`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateVisit: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/visit/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteVisit: (id) => {
    return {
      url: `${API_ROOT_URL}/visit/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  // CONTACTS

  getAllContacts: () => {
    return {
      url: `${API_ROOT_URL}/contact`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getContactById: (id) => {
    return {
      url: `${API_ROOT_URL}/contact/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createContact: (data) => {
    return {
      url: `${API_ROOT_URL}/contact`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateContact: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/contact/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteContact: (id) => {
    return {
      url: `${API_ROOT_URL}/contact/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  // USERS

  getAllUsers: (role) => {
    return {
      url: `${API_ROOT_URL}/user`,
      method: 'GET',
      headers: getAuthHeaders(),
      params: { roles: role },
    }
  },
  getUserById: (id) => {
    return {
      url: `${API_ROOT_URL}/user/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getUserBySubscriptionId: (id) => {
    return {
      url: `${API_ROOT_URL}/user/${id}/subscription`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  linkUserToLawyer: (userId, lawyerId) => {
    return {
      url: `${API_ROOT_URL}/user/link-user-to-lawyer`,
      method: 'POST',
      headers: getAuthHeaders(),
      data: { userId, lawyerId },
    }
  },
  linkUserToAgent: (userId, agentId) => {
    return {
      url: `${API_ROOT_URL}/user/link-user-to-agent`,
      method: 'POST',
      headers: getAuthHeaders(),
      data: { userId, agentId },
    }
  },
  createUser: (data) => {
    return {
      url: `${API_ROOT_URL}/user`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateUser: (id, updates) => {
    console.log('update payload', updates)
    return {
      url: `${API_ROOT_URL}/user/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteUser: (id) => {
    return {
      url: `${API_ROOT_URL}/user/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
      data: id,
    }
  },

  // CLIENTS
  getAllBusinessClients: () => {
    return {
      url: `${API_ROOT_URL}/business_client`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  getBusinessClientById: (id) => {
    return {
      url: `${API_ROOT_URL}/business_client/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createBusinessClient: (data) => {
    return {
      url: `${API_ROOT_URL}/business_client`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  updateBusinessClient: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/business_client/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteBusinessClient: (id) => {
    return {
      url: `${API_ROOT_URL}/business_client/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
  // REALESTATE

  getCities: (params) => {
    return {
      url: `${API_ROOT_URL}/cities`,
      method: 'GET',
      headers: getHeaders(),
      params,
    }
  },
  getAllRealEstates: (params) => {
    return {
      url: `${API_ROOT_URL}/real_estate`,
      method: 'GET',
      headers: getAuthHeaders(),
      params,
    }
  },
  getAllAuction: (params) => {
    return {
      url: `${API_ROOT_URL}/auction`,
      method: 'GET',
      headers: getAuthHeaders(),
      params,
    }
  },
  getAllRealEstatesByAgent: (id, params) => {
    return {
      url: `${API_ROOT_URL}/real_estate/agent/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
      params,
    }
  },
  getRealEstateById: (id) => {
    return {
      url: `${API_ROOT_URL}/real_estate/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },
  createRealEstate: (data) => {
    return {
      url: `${API_ROOT_URL}/real_estate`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },
  createRealEstateWithImport: (file) => {
    return {
      url: `${API_ROOT_URL}/import/real_estate`,
      method: 'POST',
      headers: getAuthHeaders(),
      data: {
        file,
      },
    }
  },
  updateRealEstate: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/real_estate/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  deleteRealEstate: (id) => {
    return {
      url: `${API_ROOT_URL}/real_estate/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },

  // CHECK COUPON
  getCoupon: (code) => {
    return {
      url: `${API_ROOT_URL}/coupon?code=${code}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  // RICHIESTE PARTECIPAZIONI ASTE
  getAllAuctionRequests: () => {
    return {
      url: `${API_ROOT_URL}/auction`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  getAuctionRequestById: (id) => {
    return {
      url: `${API_ROOT_URL}/auction/${id}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  updateAuctionRequestById: (id, updates) => {
    return {
      url: `${API_ROOT_URL}/auction/${id}`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: updates,
    }
  },
  getAllNotifications: () => {
    return {
      url: `${API_ROOT_URL}/notification`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  createNotification: (data) => {
    return {
      url: `${API_ROOT_URL}/notification`,
      method: 'POST',
      headers: getAuthHeaders(),
      data,
    }
  },

  // NOTIFICHE

  // GESTIONE PAGINE

  getAllPages: () => {
    return {
      url: `${API_ROOT_URL}/pages`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  getPageElements: (pageId) => {
    return {
      url: `${API_ROOT_URL}/pages/${pageId}`,
      method: 'GET',
      headers: getAuthHeaders(),
    }
  },

  updatePageElements: (pageId, contentUpdates) => {
    delete contentUpdates.id

    return {
      url: `${API_ROOT_URL}/pages/${pageId}/update-content`,
      method: 'PUT',
      headers: getAuthHeaders(),
      data: contentUpdates,
    }
  },
  deletePageElement: (id) => {
    return {
      url: `${API_ROOT_URL}/pages/${id}`,
      method: 'DELETE',
      headers: getAuthHeaders(),
    }
  },
}
export default requests

const development = {
  apiUrl: 'http://localhost:8088',
  adminUrl: 'http://localhost:3001',
  frontendUrl: 'http://localhost:3000',
}

const staging = {
  apiUrl: 'https://api.astaexpert.thecove.it',
  adminUrl: 'https://admin.astaexpert.thecove.it',
  frontendUrl: 'https://astaexpert.thecove.it',
}

const production = {
  apiUrl: 'https://api.astaexpert.it',
  adminUrl: 'https://admin.astaexpert.it',
  frontendUrl: 'https://astaexpert.it',
}

const configs = {
  development,
  staging,
  production,
}

const env = process.env.NODE_ENV || 'development'

export default configs[env]

import axios from 'axios'
import api from '../api/index'

const GET_ALL_FAQS_REQUEST = 'ASTAEXPERT/GET_ALL_FAQS_REQUEST'
const GET_ALL_FAQS_SUCCESS = 'ASTAEXPERT/GET_ALL_FAQS_SUCCESS'
const GET_ALL_FAQS_FAILURE = 'ASTAEXPERT/GET_ALL_FAQS_FAILURE'

const GET_FAQ_BY_ID_REQUEST = 'ASTAEXPERT/GET_FAQ_BY_ID_REQUEST'
const GET_FAQ_BY_ID_SUCCESS = 'ASTAEXPERT/GET_FAQ_BY_ID_SUCCESS'
const GET_FAQ_BY_ID_FAILURE = 'ASTAEXPERT/GET_FAQ_BY_ID_FAILURE'

const GET_ALL_FAQS_CATEGORIES_REQUEST = 'ASTAEXPERT/GET_ALL_FAQS_CATEGORIES_REQUEST'
const GET_ALL_FAQS_CATEGORIES_SUCCESS = 'ASTAEXPERT/GET_ALL_FAQS_CATEGORIES_SUCCESS'
const GET_ALL_FAQS_CATEGORIES_FAILURE = 'ASTAEXPERT/GET_ALL_FAQS_CATEGORIES_FAILURE'

const CREATE_FAQ_CATEGORY_REQUEST = 'ASTAEXPERT/CREATE_FAQ_CATEGORY_REQUEST'
const CREATE_FAQ_CATEGORY_SUCCESS = 'ASTAEXPERT/CREATE_FAQ_CATEGORY_SUCCESS'
const CREATE_FAQ_CATEGORY_FAILURE = 'ASTAEXPERT/CREATE_FAQ_CATEGORY_FAILURE'

const UPDATE_FAQ_CATEGORY_REQUEST = 'ASTAEXPERT/UPDATE_FAQ_CATEGORY_REQUEST'
const UPDATE_FAQ_CATEGORY_SUCCESS = 'ASTAEXPERT/UPDATE_FAQ_CATEGORY_SUCCESS'
const UPDATE_FAQ_CATEGORY_FAILURE = 'ASTAEXPERT/UPDATE_FAQ_CATEGORY_FAILURE'

const DELETE_FAQ_CATEGORY_REQUEST = 'ASTAEXPERT/DELETE_FAQ_CATEGORY_REQUEST'
const DELETE_FAQ_CATEGORY_SUCCESS = 'ASTAEXPERT/DELETE_FAQ_CATEGORY_SUCCESS'
const DELETE_FAQ_CATEGORY_FAILURE = 'ASTAEXPERT/DELETE_FAQ_CATEGORY_FAILURE'

const CREATE_FAQ_REQUEST = 'ASTAEXPERT/CREATE_FAQ_REQUEST'
const CREATE_FAQ_SUCCESS = 'ASTAEXPERT/CREATE_FAQ_SUCCESS'
const CREATE_FAQ_FAILURE = 'ASTAEXPERT/CREATE_FAQ_FAILURE'

const UPDATE_FAQ_REQUEST = 'ASTAEXPERT/UPDATE_FAQ_REQUEST'
const UPDATE_FAQ_SUCCESS = 'ASTAEXPERT/UPDATE_FAQ_SUCCESS'
const UPDATE_FAQ_FAILURE = 'ASTAEXPERT/UPDATE_FAQ_FAILURE'

const DELETE_FAQ_REQUEST = 'ASTAEXPERT/DELETE_FAQ_REQUEST'
const DELETE_FAQ_SUCCESS = 'ASTAEXPERT/DELETE_FAQ_SUCCESS'
const DELETE_FAQ_FAILURE = 'ASTAEXPERT/DELETE_FAQ_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleFaq: null,
  categories: [],
}

export default function reducerFaqs(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FAQS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_FAQS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_FAQS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_FAQ_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_FAQ_BY_ID_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singleFaq: action.payload,
      }
    case GET_FAQ_BY_ID_FAILURE:
      return { ...state, loading: false, singleFaq: '', error: action.payload }
    case GET_ALL_FAQS_CATEGORIES_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_FAQS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      }
    case GET_ALL_FAQS_CATEGORIES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case CREATE_FAQ_CATEGORY_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [action.payload, ...state.data],
      }
    case CREATE_FAQ_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_FAQ_CATEGORY_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
        singleFaq: action.payload,
      }
    case UPDATE_FAQ_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_FAQ_CATEGORY_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_FAQ_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((faq) => faq.id !== action.payload),
      }
    case DELETE_FAQ_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case CREATE_FAQ_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [action.payload, ...state.data],
      }
    case CREATE_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_FAQ_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
        singleFaq: action.payload,
      }
    case UPDATE_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_FAQ_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((faq) => faq.id != action.payload),
      }
    case DELETE_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllFaqs = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_FAQS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllFaqs()

      // console.log("params", params);

      const faqs = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_ALL_FAQS_SUCCESS,
        payload: faqs.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_FAQS_FAILURE,
        payload: error,
      })
    }
  }
}

export const getFaqById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_FAQ_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getFaqById(id)

      // console.log("params", params);

      const faq = await axios({
        url,
        method,
        headers,
      })

      // console.log('FAQ.DATA', faq.data)

      dispatch({
        type: GET_FAQ_BY_ID_SUCCESS,
        payload: faq.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_FAQ_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}
export const getAllCategories = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_FAQS_CATEGORIES_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllCategories()

      // console.log("params", params);

      const categories = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_ALL_FAQS_CATEGORIES_SUCCESS,
        payload: categories.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_FAQS_CATEGORIES_FAILURE,
        payload: error,
      })
    }
  }
}

export const createFaq = (faqData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_FAQ_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createFaq(faqData)

      const createFaq = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_FAQ_SUCCESS,
        payload: createFaq.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_FAQ_FAILURE,
        payload: error,
      })
    }
  }
}
export const createFaqCategory = (faqData) => {
  console.log('FAQ DATA', faqData)
  return async (dispatch) => {
    dispatch({
      type: CREATE_FAQ_CATEGORY_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createFaqCategory(faqData)

      const createdFaqCategory = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_FAQ_CATEGORY_SUCCESS,
        payload: createdFaqCategory.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_FAQ_CATEGORY_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateFaq = (id, faqUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FAQ_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateFaq(id, faqUpdates)

      const updatedFaq = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_FAQ_SUCCESS,
        payload: updatedFaq.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_FAQ_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateFaqCategory = (id, faqUpdates) => {
  console.log('IDID', id)
  console.log('faqUpdates', faqUpdates)

  return async (dispatch) => {
    dispatch({
      type: UPDATE_FAQ_CATEGORY_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateFaqCategory(id, faqUpdates)

      const updatedFaqCategory = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_FAQ_CATEGORY_SUCCESS,
        payload: updatedFaqCategory.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_FAQ_CATEGORY_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteFaq = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_FAQ_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteFaq(id)

      const deleteFaq = await axios({
        url,
        method,
        headers,
        data,
      })
      if (deleteFaq.data) {
        dispatch({
          type: DELETE_FAQ_SUCCESS,
          payload: id,
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_FAQ_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteFaqCategory = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_FAQ_CATEGORY_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteFaqCategory(id)

      const deleteFaqCategory = await axios({
        url,
        method,
        headers,
        data,
      })
      if (deleteFaqCategory.data) {
        dispatch({
          type: DELETE_FAQ_CATEGORY_SUCCESS,
          payload: id,
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_FAQ_CATEGORY_FAILURE,
        payload: error,
      })
    }
  }
}

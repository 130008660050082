import axios from 'axios'
import api from '../api/index'

const GET_ALL_AUCTION_REQUESTS_REQUEST = 'ASTAEXPERT/GET_ALL_AUCTION_REQUESTS_REQUEST'
const GET_ALL_AUCTION_REQUESTS_SUCCESS = 'ASTAEXPERT/GET_ALL_AUCTION_REQUESTS_SUCCESS'
const GET_ALL_AUCTION_REQUESTS_FAILURE = 'ASTAEXPERT/GET_ALL_AUCTION_REQUESTS_FAILURE'

const GET_AUCTION_REQUEST_BY_ID_REQUEST = 'ASTAEXPERT/GET_AUCTION_REQUEST_BY_ID_REQUEST'
const GET_AUCTION_REQUEST_BY_ID_SUCCESS = 'ASTAEXPERT/GET_AUCTION_REQUEST_BY_ID_SUCCESS'
const GET_AUCTION_REQUEST_BY_ID_FAILURE = 'ASTAEXPERT/GET_AUCTION_REQUEST_BY_ID_FAILURE'

const UPDATE_AUCTION_REQUESTS_BY_ID_REQUEST = 'ASTAEXPERT/UPDATE_AUCTION_REQUESTS_BY_ID_REQUEST'
const UPDATE_AUCTION_REQUESTS_BY_ID_SUCCESS = 'ASTAEXPERT/UPDATE_AUCTION_REQUESTS_BY_ID_SUCCESS'
const UPDATE_AUCTION_REQUESTS_BY_ID_FAILURE = 'ASTAEXPERT/UPDATE_AUCTION_REQUESTS_BY_ID_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleAuctionRequest: null,
}

export default function reducerAuctionRequests(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_AUCTION_REQUESTS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_AUCTION_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_AUCTION_REQUESTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case GET_AUCTION_REQUEST_BY_ID_REQUEST:
      return { ...state, loading: true, error: false, singleAuctionRequest: null }
    case GET_AUCTION_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAuctionRequest: action.payload,
      }
    case GET_AUCTION_REQUEST_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case UPDATE_AUCTION_REQUESTS_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_AUCTION_REQUESTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAuctionRequest: action.payload,
      }
    case UPDATE_AUCTION_REQUESTS_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export const getAllAuctionRequests = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_AUCTION_REQUESTS_REQUEST,
    })

    try {
      console.log('called')
      const { url, method, headers } = api.getAllAuctionRequests()

      const auctionRequests = await axios({
        url,
        method,
        headers,
      })

      console.log('RESPONSE get all auction requests', auctionRequests.data)

      dispatch({
        type: GET_ALL_AUCTION_REQUESTS_SUCCESS,
        payload: auctionRequests.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_AUCTION_REQUESTS_FAILURE,
        payload: error,
      })
    }
  }
}

export const getAuctionRequestById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_AUCTION_REQUEST_BY_ID_REQUEST,
    })

    try {
      console.log('called')
      const { url, method, headers } = api.getAuctionRequestById(id)

      const auctionRequest = await axios({
        url,
        method,
        headers,
      })

      console.log('RESPONSE get auction request by id', auctionRequest.data)

      dispatch({
        type: GET_AUCTION_REQUEST_BY_ID_SUCCESS,
        payload: auctionRequest.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_AUCTION_REQUEST_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateAuctionRequestById = (id, updates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AUCTION_REQUESTS_BY_ID_REQUEST,
    })

    try {
      console.log('called')
      const { url, method, headers, data } = api.updateAuctionRequestById(id, updates)

      console.log({
        url,
        method,
        headers,
        data,
      })

      const auctionRequest = await axios({
        url,
        method,
        headers,
        data,
      })

      console.log('RESPONSE get auction request by id', auctionRequest.data)

      dispatch({
        type: UPDATE_AUCTION_REQUESTS_BY_ID_SUCCESS,
        payload: auctionRequest.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: UPDATE_AUCTION_REQUESTS_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

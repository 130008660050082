import axios from 'axios'
import api from '../api/index'

const GET_ALL_CONTACTS_REQUEST = 'ASTAEXPERT/GET_ALL_CONTACTS_REQUEST'
const GET_ALL_CONTACTS_SUCCESS = 'ASTAEXPERT/GET_ALL_CONTACTS_SUCCESS'
const GET_ALL_CONTACTS_FAILURE = 'ASTAEXPERT/GET_ALL_CONTACTS_FAILURE'

const GET_CONTACT_BY_ID_REQUEST = 'ASTAEXPERT/GET_CONTACT_BY_ID_REQUEST'
const GET_CONTACT_BY_ID_SUCCESS = 'ASTAEXPERT/GET_CONTACT_BY_ID_SUCCESS'
const GET_CONTACT_BY_ID_FAILURE = 'ASTAEXPERT/GET_CONTACT_BY_ID_FAILURE'

const CREATE_CONTACT_REQUEST = 'ASTAEXPERT/CREATE_CONTACT_REQUEST'
const CREATE_CONTACT_SUCCESS = 'ASTAEXPERT/CREATE_CONTACT_SUCCESS'
const CREATE_CONTACT_FAILURE = 'ASTAEXPERT/CREATE_CONTACT_FAILURE'

const UPDATE_CONTACT_REQUEST = 'ASTAEXPERT/UPDATE_CONTACT_REQUEST'
const UPDATE_CONTACT_SUCCESS = 'ASTAEXPERT/UPDATE_CONTACT_SUCCESS'
const UPDATE_CONTACT_FAILURE = 'ASTAEXPERT/UPDATE_CONTACT_FAILURE'

const DELETE_CONTACT_REQUEST = 'ASTAEXPERT/DELETE_CONTACT_REQUEST'
const DELETE_CONTACT_SUCCESS = 'ASTAEXPERT/DELETE_CONTACT_SUCCESS'
const DELETE_CONTACT_FAILURE = 'ASTAEXPERT/DELETE_CONTACT_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleContact: '',
}

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTACTS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_CONTACTS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_CONTACTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_CONTACT_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_CONTACT_BY_ID_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singleContact: action.payload,
      }
    case GET_CONTACT_BY_ID_FAILURE:
      return { ...state, loading: false, singleContact: '', error: action.payload }
    case CREATE_CONTACT_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleContact: action.payload,
      }
    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_CONTACT_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleContact: action.payload,
      }
    case UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_CONTACT_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((contact) => contact.id != action.payload),
      }
    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllContacts = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_CONTACTS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllContacts()

      // console.log("params", params);

      const contacts = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_ALL_CONTACTS_SUCCESS,
        payload: contacts.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_CONTACTS_FAILURE,
        payload: error,
      })
    }
  }
}
export const getContactById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CONTACT_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getContactById(id)

      // console.log("params", params);

      const contact = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_CONTACT_BY_ID_SUCCESS,
        payload: contact.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_CONTACT_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createContact = (contactData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CONTACT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createContact(contactData)

      const createdContact = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_CONTACT_SUCCESS,
        payload: createdContact.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_CONTACT_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateContact = (id, contactUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CONTACT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateContact(id, contactUpdates)

      const updatedContact = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_CONTACT_SUCCESS,
        payload: updatedContact.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_CONTACT_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteContact = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CONTACT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteContact(id)

      const deletedContact = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: DELETE_CONTACT_SUCCESS,
        payload: id,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_CONTACT_FAILURE,
        payload: error,
      })
    }
  }
}

import axios from 'axios'
import api from '../api/index'

const GET_ALL_USERS_REQUEST = 'ASTAEXPERT/GET_ALL_USERS_REQUEST'
const GET_ALL_USERS_SUCCESS = 'ASTAEXPERT/GET_ALL_USERS_SUCCESS'
const GET_ALL_USERS_FAILURE = 'ASTAEXPERT/GET_ALL_USERS_FAILURE'

const GET_ALL_AGENTS_REQUEST = 'ASTAEXPERT/GET_ALL_AGENTS_REQUEST'
const GET_ALL_AGENTS_SUCCESS = 'ASTAEXPERT/GET_ALL_AGENTS_SUCCESS'
const GET_ALL_AGENTS_FAILURE = 'ASTAEXPERT/GET_ALL_AGENTS_FAILURE'

const GET_ALL_LAWYERS_REQUEST = 'ASTAEXPERT/GET_ALL_LAWYERS_REQUEST'
const GET_ALL_LAWYERS_SUCCESS = 'ASTAEXPERT/GET_ALL_LAWYERS_SUCCESS'
const GET_ALL_LAWYERS_FAILURE = 'ASTAEXPERT/GET_ALL_LAWYERS_FAILURE'

const GET_ALL_OPERATORS_REQUEST = 'ASTAEXPERT/GET_ALL_OPERATORS_REQUEST'
const GET_ALL_OPERATORS_SUCCESS = 'ASTAEXPERT/GET_ALL_OPERATORS_SUCCESS'
const GET_ALL_OPERATORS_FAILURE = 'ASTAEXPERT/GET_ALL_OPERATORS_FAILURE'

const GET_USER_BY_ID_REQUEST = 'ASTAEXPERT/GET_USER_BY_ID_REQUEST'
const GET_USER_BY_ID_SUCCESS = 'ASTAEXPERT/GET_USER_BY_ID_SUCCESS'
const GET_USER_BY_ID_FAILURE = 'ASTAEXPERT/GET_USER_BY_ID_FAILURE'

const LINK_USER_TO_LAWYER_REQUEST = 'ASTAEXPERT/LINK_USER_TO_LAWYER_REQUEST'
const LINK_USER_TO_LAWYER_SUCCESS = 'ASTAEXPERT/LINK_USER_TO_LAWYER_SUCCESS'
const LINK_USER_TO_LAWYER_FAILURE = 'ASTAEXPERT/LINK_USER_TO_LAWYER_FAILURE'

const LINK_USER_TO_AGENT_REQUEST = 'ASTAEXPERT/LINK_USER_TO_AGENT_REQUEST'
const LINK_USER_TO_AGENT_SUCCESS = 'ASTAEXPERT/LINK_USER_TO_AGENT_SUCCESS'
const LINK_USER_TO_AGENT_FAILURE = 'ASTAEXPERT/LINK_USER_TO_AGENT_FAILURE'

const CREATE_USER_REQUEST = 'ASTAEXPERT/CREATE_USER_REQUEST'
const CREATE_USER_SUCCESS = 'ASTAEXPERT/CREATE_USER_SUCCESS'
const CREATE_USER_FAILURE = 'ASTAEXPERT/CREATE_USER_FAILURE'

const UPDATE_USER_REQUEST = 'ASTAEXPERT/UPDATE_USER_REQUEST'
const UPDATE_USER_SUCCESS = 'ASTAEXPERT/UPDATE_USER_SUCCESS'
const UPDATE_USER_FAILURE = 'ASTAEXPERT/UPDATE_USER_FAILURE'

const DELETE_USER_REQUEST = 'ASTAEXPERT/DELETE_USER_REQUEST'
const DELETE_USER_SUCCESS = 'ASTAEXPERT/DELETE_USER_SUCCESS'
const DELETE_USER_FAILURE = 'ASTAEXPERT/DELETE_USER_FAILURE'

const initialState = {
  loading: false,
  creating: false,
  loadingUpdate: false,
  error: false,
  data: [],
  // singleUser: '',
  singleUser: null,
  newUser: null,
  userLinked: '',
  userAgentLinked: '',
  agents: [],
  lawyers: [],
  operators: [],
}

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_USERS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_USERS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case GET_ALL_AGENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload,
      }
    case GET_ALL_AGENTS_FAILURE:
      return { ...state, loading: false, agents: [], error: action.payload }

    case GET_ALL_LAWYERS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_LAWYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        lawyers: action.payload,
      }
    case GET_ALL_LAWYERS_FAILURE:
      return { ...state, loading: false, lawyers: [], error: action.payload }

    case GET_ALL_OPERATORS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_OPERATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        operators: action.payload,
      }
    case GET_ALL_OPERATORS_FAILURE:
      return { ...state, loading: false, operators: [], error: action.payload }

    case GET_USER_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_USER_BY_ID_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singleUser: action.payload,
      }
    case GET_USER_BY_ID_FAILURE:
      return { ...state, loading: false, singleUser: '', error: action.payload }

    case LINK_USER_TO_LAWYER_REQUEST:
      return { ...state, loadingUpdate: true, error: false }
    case LINK_USER_TO_LAWYER_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loadingUpdate: false,
        userLinked: action.payload,
      }
    case LINK_USER_TO_LAWYER_FAILURE:
      return { ...state, loading: false, singleUser: '', error: action.payload }

    case LINK_USER_TO_AGENT_REQUEST:
      return { ...state, loadingUpdate: true, error: false }
    case LINK_USER_TO_AGENT_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loadingUpdate: false,
        userAgentLinked: action.payload,
      }
    case LINK_USER_TO_AGENT_FAILURE:
      return { ...state, loading: false, singleUser: '', error: action.payload }

    case CREATE_USER_REQUEST:
      return { ...state, creating: true, error: false }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        creating: false,
        newUser: action.payload,
      }
    case CREATE_USER_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.payload,
      }

    case UPDATE_USER_REQUEST:
      return { ...state, loadingUpdate: true, error: false }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        singleUser: action.payload,
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_USER_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleUser: action.payload,
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

const getDispatchTypesByRole = (role) => {
  switch (role) {
    case 'lawyer': {
      return {
        request: GET_ALL_LAWYERS_REQUEST,
        success: GET_ALL_LAWYERS_SUCCESS,
        failure: GET_ALL_LAWYERS_FAILURE,
      }
    }
    case 'agent': {
      return {
        request: GET_ALL_AGENTS_REQUEST,
        success: GET_ALL_AGENTS_SUCCESS,
        failure: GET_ALL_AGENTS_FAILURE,
      }
    }
    case 'operator': {
      return {
        request: GET_ALL_OPERATORS_REQUEST,
        success: GET_ALL_OPERATORS_SUCCESS,
        failure: GET_ALL_OPERATORS_FAILURE,
      }
    }
    default: {
      return {
        request: GET_ALL_USERS_REQUEST,
        success: GET_ALL_USERS_SUCCESS,
        failure: GET_ALL_USERS_FAILURE,
      }
    }
  }
}

export const getAllUsers = (role) => {
  const dispatchType = getDispatchTypesByRole(role)

  return async (dispatch) => {
    dispatch({
      type: dispatchType.request,
    })

    try {
      const { url, method, headers, params } = api.getAllUsers(role)

      const users = await axios({
        url,
        method,
        headers,
        params,
      })

      dispatch({
        type: dispatchType.success,
        payload: users.data,
      })
      console.log('response', users.data)

      return users.data
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: dispatchType.failure,
        payload: error,
      })
    }
  }
}

export const getUserById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_BY_ID_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.getUserById(id)

      const response = await axios({
        url,
        method,
        headers,
        data,
      })

      if (response.statusText === 'OK') {
        const { url, method, headers, data } = api.getUserBySubscriptionId(id)

        let subscription
        try {
          const subscriptionRes = await axios({
            url,
            method,
            headers,
            data,
          })
          subscription = subscriptionRes?.data
        } catch (err) {
          console.log('Error while getting subscription', err)
        }

        dispatch({
          type: GET_USER_BY_ID_SUCCESS,
          payload: {
            ...response.data,
            subscription,
          },
        })
      } else {
        dispatch({
          type: GET_USER_BY_ID_FAILURE,
          payload: 'Error while fetching user',
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: GET_USER_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const linkUserToLawyer = (userId, lawyerId) => {
  console.log('lawyerId', lawyerId)
  return async (dispatch) => {
    dispatch({
      type: LINK_USER_TO_LAWYER_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.linkUserToLawyer(userId, lawyerId)
      console.log('link law data', data)
      const user = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: LINK_USER_TO_LAWYER_SUCCESS,
        payload: 'success',
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: LINK_USER_TO_LAWYER_FAILURE,
        payload: error,
      })
    }
  }
}

export const linkUserToAgent = (userId, agentId) => {
  console.log('agentId', agentId)
  return async (dispatch) => {
    dispatch({
      type: LINK_USER_TO_AGENT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.linkUserToAgent(userId, agentId)
      // console.log('link law data', data)
      const user = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: LINK_USER_TO_AGENT_SUCCESS,
        payload: 'success',
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: LINK_USER_TO_AGENT_FAILURE,
        payload: error,
      })
    }
  }
}

export const createUser = (userData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_USER_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createUser(userData)

      const createdUser = await axios({
        url,
        method,
        headers,
        data,
      })

      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: createdUser.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateUser = (id, userUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateUser(id, userUpdates)
      console.log('url', url)
      console.log('method', method)
      console.log('headers', headers)
      console.log('data', data)
      delete userUpdates.id

      const updatedUser = await axios({
        url,
        method,
        headers,
        data,
      })
      console.log('payload', data)
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: updatedUser.data,
      })
      console.log('payload', updatedUser.data)
      return 'updated'
    } catch (error) {
      console.log('redux error in update user', error)
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_USER_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteUser(id)

      const deletedUser = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: deletedUser.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: error,
      })
    }
  }
}

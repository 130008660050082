import axios from 'axios'
import api from '../api/index'

const GET_ALL_NOTIFICATIONS_REQUEST = 'ASTAEXPERT/GET_ALL_NOTIFICATIONS_REQUEST'
const GET_ALL_NOTIFICATIONS_SUCCESS = 'ASTAEXPERT/GET_ALL_NOTIFICATIONS_SUCCESS'
const GET_ALL_NOTIFICATIONS_FAILURE = 'ASTAEXPERT/GET_ALL_NOTIFICATIONS_FAILURE'

const CREATE_NOTIFICATION_REQUEST = 'ASTAEXPERT/CREATE_NOTIFICATION_REQUEST'
const CREATE_NOTIFICATION_SUCCESS = 'ASTAEXPERT/CREATE_NOTIFICATION_SUCCESS'
const CREATE_NOTIFICATION_FAILURE = 'ASTAEXPERT/CREATE_NOTIFICATION_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
}

export default function reducerNotifications(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case CREATE_NOTIFICATION_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleVisit: action.payload,
      }
    case CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllNotifications = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_NOTIFICATIONS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllNotifications()

      const notifications = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_ALL_NOTIFICATIONS_SUCCESS,
        payload: notifications.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_NOTIFICATIONS_FAILURE,
        payload: error,
      })
    }
  }
}

export const createNotification = (notificationData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_NOTIFICATION_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createNotification(notificationData)

      const createdNotification = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_NOTIFICATION_SUCCESS,
        payload: createdNotification.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_NOTIFICATION_FAILURE,
        payload: error,
      })
    }
  }
}

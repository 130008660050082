import axios from 'axios'
import api from '../api/index'

const GET_ALL_BUSINESS_CLIENTS_REQUEST = 'ASTAEXPERT/GET_ALL_BUSINESS_CLIENTS_REQUEST'
const GET_ALL_BUSINESS_CLIENTS_SUCCESS = 'ASTAEXPERT/GET_ALL_BUSINESS_CLIENTS_SUCCESS'
const GET_ALL_BUSINESS_CLIENTS_FAILURE = 'ASTAEXPERT/GET_ALL_BUSINESS_CLIENTS_FAILURE'

const GET_BUSINESS_CLIENT_BY_ID_REQUEST = 'ASTAEXPERT/GET_BUSINESS_CLIENT_BY_ID_REQUEST'
const GET_BUSINESS_CLIENT_BY_ID_SUCCESS = 'ASTAEXPERT/GET_BUSINESS_CLIENT_BY_ID_SUCCESS'
const GET_BUSINESS_CLIENT_BY_ID_FAILURE = 'ASTAEXPERT/GET_BUSINESS_CLIENT_BY_ID_FAILURE'

const CREATE_BUSINESS_CLIENT_REQUEST = 'ASTAEXPERT/CREATE_BUSINESS_CLIENT_REQUEST'
const CREATE_BUSINESS_CLIENT_SUCCESS = 'ASTAEXPERT/CREATE_BUSINESS_CLIENT_SUCCESS'
const CREATE_BUSINESS_CLIENT_FAILURE = 'ASTAEXPERT/CREATE_BUSINESS_CLIENT_FAILURE'

const UPDATE_BUSINESS_CLIENT_REQUEST = 'ASTAEXPERT/UPDATE_BUSINESS_CLIENT_REQUEST'
const UPDATE_BUSINESS_CLIENT_SUCCESS = 'ASTAEXPERT/UPDATE_BUSINESS_CLIENT_SUCCESS'
const UPDATE_BUSINESS_CLIENT_FAILURE = 'ASTAEXPERT/UPDATE_BUSINESS_CLIENT_FAILURE'

const DELETE_BUSINESS_CLIENT_REQUEST = 'ASTAEXPERT/DELETE_BUSINESS_CLIENT_REQUEST'
const DELETE_BUSINESS_CLIENT_SUCCESS = 'ASTAEXPERT/DELETE_BUSINESS_CLIENT_SUCCESS'
const DELETE_BUSINESS_CLIENT_FAILURE = 'ASTAEXPERT/DELETE_BUSINESS_CLIENT_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleBusinessClient: null,
}

export default function reducerFaqs(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BUSINESS_CLIENTS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_BUSINESS_CLIENTS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_BUSINESS_CLIENTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case GET_BUSINESS_CLIENT_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_BUSINESS_CLIENT_BY_ID_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singleBusinessClient: action.payload,
      }
    case GET_BUSINESS_CLIENT_BY_ID_FAILURE:
      return { ...state, loading: false, singleBusinessClient: null, error: action.payload }

    case CREATE_BUSINESS_CLIENT_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_BUSINESS_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      }
    case CREATE_BUSINESS_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_BUSINESS_CLIENT_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_BUSINESS_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBusinessClient: action.payload,
      }
    case UPDATE_BUSINESS_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_BUSINESS_CLIENT_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_BUSINESS_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((businessClient) => businessClient.id != action.payload),
      }
    case DELETE_BUSINESS_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllBusinessClients = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_BUSINESS_CLIENTS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllBusinessClients()

      // console.log("params", params);

      const businessClient = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_ALL_BUSINESS_CLIENTS_SUCCESS,
        payload: businessClient.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_BUSINESS_CLIENTS_FAILURE,
        payload: error,
      })
    }
  }
}

export const getBusinessClientById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_BUSINESS_CLIENT_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getBusinessClientById(id)

      // console.log("params", params);

      const businessClient = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_BUSINESS_CLIENT_BY_ID_SUCCESS,
        payload: businessClient.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_BUSINESS_CLIENT_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createBusinessClient = (businessClientData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_BUSINESS_CLIENT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createBusinessClient(businessClientData)

      const createdBusinessClient = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_BUSINESS_CLIENT_SUCCESS,
        payload: createdBusinessClient.data,
      })
      console.log('payload', createdBusinessClient.data)
      return 'success'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_BUSINESS_CLIENT_FAILURE,
        payload: error,
      })
    }
  }
}
export const updateBusinessClient = (id, BusinessClassUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_BUSINESS_CLIENT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateBusinessClient(id, BusinessClassUpdates)

      const updatedBusinessClient = await axios({
        url,
        method,
        headers,
        data,
      })

      dispatch({
        type: UPDATE_BUSINESS_CLIENT_SUCCESS,
        payload: updatedBusinessClient.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_BUSINESS_CLIENT_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteBusinessClient = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_BUSINESS_CLIENT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteBusinessClient(id)

      const deletedBusinessClient = await axios({
        url,
        method,
        headers,
        data,
      })
      if (deletedBusinessClient.data) {
        dispatch({
          type: DELETE_BUSINESS_CLIENT_SUCCESS,
          payload: id,
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_BUSINESS_CLIENT_FAILURE,
        payload: error,
      })
    }
  }
}

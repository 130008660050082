import axios from 'axios'
import api from '../api/index'

const GET_ALL_COURTHOUSES_REQUEST = 'ASTAEXPERT/GET_ALL_COURTHOUSES_REQUEST'
const GET_ALL_COURTHOUSES_SUCCESS = 'ASTAEXPERT/GET_ALL_COURTHOUSES_SUCCESS'
const GET_ALL_COURTHOUSES_FAILURE = 'ASTAEXPERT/GET_ALL_COURTHOUSES_FAILURE'

const GET_COURTHOUSE_BY_ID_REQUEST = 'ASTAEXPERT/GET_COURTHOUSE_BY_ID_REQUEST'
const GET_COURTHOUSE_BY_ID_SUCCESS = 'ASTAEXPERT/GET_COURTHOUSE_BY_ID_SUCCESS'
const GET_COURTHOUSE_BY_ID_FAILURE = 'ASTAEXPERT/GET_COURTHOUSE_BY_ID_FAILURE'

const CREATE_COURTHOUSE_REQUEST = 'ASTAEXPERT/CREATE_COURTHOUSE_REQUEST'
const CREATE_COURTHOUSE_SUCCESS = 'ASTAEXPERT/CREATE_COURTHOUSE_SUCCESS'
const CREATE_COURTHOUSE_FAILURE = 'ASTAEXPERT/CREATE_COURTHOUSE_FAILURE'

const UPDATE_COURTHOUSE_REQUEST = 'ASTAEXPERT/UPDATE_COURTHOUSE_REQUEST'
const UPDATE_COURTHOUSE_SUCCESS = 'ASTAEXPERT/UPDATE_COURTHOUSE_SUCCESS'
const UPDATE_COURTHOUSE_FAILURE = 'ASTAEXPERT/UPDATE_COURTHOUSE_FAILURE'

const DELETE_COURTHOUSE_REQUEST = 'ASTAEXPERT/DELETE_COURTHOUSE_REQUEST'
const DELETE_COURTHOUSE_SUCCESS = 'ASTAEXPERT/DELETE_COURTHOUSE_SUCCESS'
const DELETE_COURTHOUSE_FAILURE = 'ASTAEXPERT/DELETE_COURTHOUSE_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleCourthouse: '',
}

export default function reducerCourtHouses(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COURTHOUSES_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_COURTHOUSES_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_COURTHOUSES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_COURTHOUSE_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_COURTHOUSE_BY_ID_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singleCourthouse: action.payload,
      }
    case GET_COURTHOUSE_BY_ID_FAILURE:
      return { ...state, loading: false, singleCourthouse: '', error: action.payload }
    case CREATE_COURTHOUSE_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_COURTHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleCOURTHOUSE: action.payload,
      }
    case CREATE_COURTHOUSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_COURTHOUSE_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_COURTHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCourthouse: action.payload,
      }
    case UPDATE_COURTHOUSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_COURTHOUSE_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_COURTHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleCourthouse: action.payload,
      }
    case DELETE_COURTHOUSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllCourthouses = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_COURTHOUSES_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllCourthouses()

      // console.log("params", params);

      const courthouses = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_ALL_COURTHOUSES_SUCCESS,
        payload: courthouses.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_COURTHOUSES_FAILURE,
        payload: error,
      })
    }
  }
}
export const getCourthouseById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_COURTHOUSE_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getCourthouseById(id)

      // console.log("params", params);

      const courthouse = await axios({
        url,
        method,
        headers,
      })

      // console.log(properties.data);

      dispatch({
        type: GET_COURTHOUSE_BY_ID_SUCCESS,
        payload: courthouse.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_COURTHOUSE_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createCourthouse = (courthouseData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_COURTHOUSE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createCourthouse(courthouseData)

      const createdCourthouse = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_COURTHOUSE_SUCCESS,
        payload: createdCourthouse.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_COURTHOUSE_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateCourthouse = (id, courthouseUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_COURTHOUSE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateCourthouse(id, courthouseUpdates)

      const updatedCourthouse = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_COURTHOUSE_SUCCESS,
        payload: updatedCourthouse.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_COURTHOUSE_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteCourthouse = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_COURTHOUSE_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteCourthouse(id)

      const deleteCourthouse = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: DELETE_COURTHOUSE_SUCCESS,
        payload: deleteCourthouse.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_COURTHOUSE_FAILURE,
        payload: error,
      })
    }
  }
}

import axios from 'axios'
import api from '../api/index'

const GET_ALL_AUCTIONS_REQUEST = 'ASTAEXPERT/GET_ALL_AUCTIONS_REQUEST'
const GET_ALL_AUCTIONS_SUCCESS = 'ASTAEXPERT/GET_ALL_AUCTIONS_SUCCESS'
const GET_ALL_AUCTIONS_FAILURE = 'ASTAEXPERT/GET_ALL_AUCTIONS_FAILURE'

const GET_AUCTION_BY_ID_REQUEST = 'ASTAEXPERT/GET_AUCTION_BY_ID_REQUEST'
const GET_AUCTION_BY_ID_SUCCESS = 'ASTAEXPERT/GET_AUCTION_BY_ID_SUCCESS'
const GET_AUCTION_BY_ID_FAILURE = 'ASTAEXPERT/GET_AUCTION_BY_ID_FAILURE'

const UPDATE_AUCTION_REQUEST = 'ASTAEXPERT/UPDATE_AUCTION_REQUEST'
const UPDATE_AUCTION_SUCCESS = 'ASTAEXPERT/UPDATE_AUCTION_SUCCESS'
const UPDATE_AUCTION_FAILURE = 'ASTAEXPERT/UPDATE_AUCTION_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: { auctions: [], pagination: {} },
  singleAuction: {},
}

export default function reducerAuctionss(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_AUCTIONS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_AUCTIONS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: {
          pagination: action.payload.pagination,
          auctions: action.payload.realEstates,
        },
      }
    case GET_ALL_AUCTIONS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    case UPDATE_AUCTION_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_AUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAuction: action.payload,
      }
    case GET_AUCTION_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_AUCTION_BY_ID_SUCCESS:
      console.log('payload', action.payload)
      console.log('data', state.data)
      return {
        ...state,
        loading: false,
        singleAuction: state.data.auctions.find((auction) => auction.auction.id === action.payload),
      }
    case GET_AUCTION_BY_ID_FAILURE:
      return { ...state, loading: false, singleAuction: {}, error: action.payload }

    default:
      return state
  }
}

export const getAllAuctions = (filters) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_AUCTIONS_REQUEST,
    })

    try {
      const { url, method, headers, params } = api.getAllRealEstates({
        ...filters,
        saleType: 'auction',
      })

      // console.log("params", params);

      const auctions = await axios({
        url,
        method,
        headers,
        params,
      })

      console.log('RESPONSE', auctions.data)

      dispatch({
        type: GET_ALL_AUCTIONS_SUCCESS,
        payload: auctions.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_AUCTIONS_FAILURE,
        payload: error,
      })
    }
  }
}

export const getAuctionById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_AUCTION_BY_ID_REQUEST,
    })

    try {
      dispatch({
        type: GET_AUCTION_BY_ID_SUCCESS,
        payload: id,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_AUCTION_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateAuction = (id, auctionUpdates) => {
  console.log('updateData', auctionUpdates)
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AUCTION_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateRealEstate(id, auctionUpdates)

      const updatedAuction = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_AUCTION_SUCCESS,
        payload: updatedAuction.data,
      })
      return 'updated'
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)
      dispatch({
        type: UPDATE_AUCTION_FAILURE,
        payload: error,
      })
    }
  }
}

import axios from 'axios'
import api from '../api/index'

const GET_ALL_FORMS_REQUEST = 'ASTAEXPERT/GET_ALL_FORMS_REQUEST'
const GET_ALL_FORMS_SUCCESS = 'ASTAEXPERT/GET_ALL_FORMS_SUCCESS'
const GET_ALL_FORMS_FAILURE = 'ASTAEXPERT/GET_ALL_FORMS_FAILURE'

const GET_FORM_BY_ID_REQUEST = 'ASTAEXPERT/GET_FORM_BY_ID_REQUEST'
const GET_FORM_BY_ID_SUCCESS = 'ASTAEXPERT/GET_FORM_BY_ID_SUCCESS'
const GET_FORM_BY_ID_FAILURE = 'ASTAEXPERT/GET_FORM_BY_ID_FAILURE'

const CREATE_FORM_REQUEST = 'ASTAEXPERT/CREATE_FORM_REQUEST'
const CREATE_FORM_SUCCESS = 'ASTAEXPERT/CREATE_FORM_SUCCESS'
const CREATE_FORM_FAILURE = 'ASTAEXPERT/CREATE_FORM_FAILURE'

const UPDATE_FORM_REQUEST = 'ASTAEXPERT/UPDATE_FORM_REQUEST'
const UPDATE_FORM_SUCCESS = 'ASTAEXPERT/UPDATE_FORM_SUCCESS'
const UPDATE_FORM_FAILURE = 'ASTAEXPERT/UPDATE_FORM_FAILURE'

const DELETE_FORM_REQUEST = 'ASTAEXPERT/DELETE_FORM_REQUEST'
const DELETE_FORM_SUCCESS = 'ASTAEXPERT/DELETE_FORM_SUCCESS'
const DELETE_FORM_FAILURE = 'ASTAEXPERT/DELETE_FORM_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleForm: '',
}

export default function reducerForms(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FORMS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_FORMS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_FORMS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_FORM_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_FORM_BY_ID_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        singleForm: action.payload,
      }
    case GET_FORM_BY_ID_FAILURE:
      return { ...state, loading: false, singleForm: '', error: action.payload }

    case CREATE_FORM_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      }
    case CREATE_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_FORM_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        singleForm: action.payload,
      }
    case UPDATE_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_FORM_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((form) => form.id != action.payload),
      }
    case DELETE_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllForms = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_FORMS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllForms()

      const forms = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_ALL_FORMS_SUCCESS,
        payload: forms.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_FORMS_FAILURE,
        payload: error,
      })
    }
  }
}
export const getFormById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_FORM_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getFormById(id)

      const form = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_FORM_BY_ID_SUCCESS,
        payload: form.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_FORM_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createForm = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_FORM_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createForm(formData)

      const createForm = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_FORM_SUCCESS,
        payload: createForm.data,
      })
    } catch (error) {
      console.log('error', error.response)
      dispatch({
        type: CREATE_FORM_FAILURE,
        payload: error,
      })
    }
  }
}
export const updateForm = (id, formUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateForm(id, formUpdates)

      const updatedForm = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_FORM_SUCCESS,
        payload: updatedForm.data,
      })
      return 'updated'
    } catch (error) {
      console.log('error', error.response)
      dispatch({
        type: UPDATE_FORM_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteForm = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_FORM_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteForm(id)

      const deletedForm = await axios({
        url,
        method,
        headers,
        data,
      })
      if (deletedForm.data) {
        dispatch({
          type: DELETE_FORM_SUCCESS,
          payload: id,
        })
      }
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_FORM_FAILURE,
        payload: error,
      })
    }
  }
}

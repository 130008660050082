import axios from 'axios'
import api from '../api/index'

const GET_ALL_VISITS_REQUEST = 'ASTAEXPERT/GET_ALL_VISITS_REQUEST'
const GET_ALL_VISITS_SUCCESS = 'ASTAEXPERT/GET_ALL_VISITS_SUCCESS'
const GET_ALL_VISITS_FAILURE = 'ASTAEXPERT/GET_ALL_VISITS_FAILURE'

const GET_VISIT_BY_ID_REQUEST = 'ASTAEXPERT/GET_VISIT_BY_ID_REQUEST'
const GET_VISIT_BY_ID_SUCCESS = 'ASTAEXPERT/GET_VISIT_BY_ID_SUCCESS'
const GET_VISIT_BY_ID_FAILURE = 'ASTAEXPERT/GET_VISIT_BY_ID_FAILURE'

const CREATE_VISIT_REQUEST = 'ASTAEXPERT/CREATE_VISIT_REQUEST'
const CREATE_VISIT_SUCCESS = 'ASTAEXPERT/CREATE_VISIT_SUCCESS'
const CREATE_VISIT_FAILURE = 'ASTAEXPERT/CREATE_VISIT_FAILURE'

const UPDATE_VISIT_REQUEST = 'ASTAEXPERT/UPDATE_VISIT_REQUEST'
const UPDATE_VISIT_SUCCESS = 'ASTAEXPERT/UPDATE_VISIT_SUCCESS'
const UPDATE_VISIT_FAILURE = 'ASTAEXPERT/UPDATE_VISIT_FAILURE'

const DELETE_VISIT_REQUEST = 'ASTAEXPERT/DELETE_VISIT_REQUEST'
const DELETE_VISIT_SUCCESS = 'ASTAEXPERT/DELETE_VISIT_SUCCESS'
const DELETE_VISIT_FAILURE = 'ASTAEXPERT/DELETE_VISIT_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
  singleVisit: '',
}

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VISITS_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_ALL_VISITS_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_ALL_VISITS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }
    case GET_VISIT_BY_ID_REQUEST:
      return { ...state, loading: true, error: false }
    case GET_VISIT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleVisit: action.payload,
      }
    case GET_VISIT_BY_ID_FAILURE:
      return { ...state, loading: false, singleVisit: '', error: action.payload }
    case CREATE_VISIT_REQUEST:
      return { ...state, loading: true, error: false }
    case CREATE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleVisit: action.payload,
      }
    case CREATE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_VISIT_REQUEST:
      return { ...state, loading: true, error: false }
    case UPDATE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleVisit: action.payload,
      }
    case UPDATE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case DELETE_VISIT_REQUEST:
      return { ...state, loading: true, error: false }
    case DELETE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        // singleVisit: action.payload,
      }
    case DELETE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export const getAllVisits = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_VISITS_REQUEST,
    })

    try {
      const { url, method, headers } = api.getAllVisits()

      const visits = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_ALL_VISITS_SUCCESS,
        payload: visits.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_ALL_VISITS_FAILURE,
        payload: error,
      })
    }
  }
}

export const getVisitById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_VISIT_BY_ID_REQUEST,
    })

    try {
      const { url, method, headers } = api.getVisitById(id)

      const visits = await axios({
        url,
        method,
        headers,
      })

      dispatch({
        type: GET_VISIT_BY_ID_SUCCESS,
        payload: visits.data,
      })
    } catch (error) {
      console.log('error', error)
      console.log('error', error.response)

      dispatch({
        type: GET_VISIT_BY_ID_FAILURE,
        payload: error,
      })
    }
  }
}

export const createVisit = (visitData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_VISIT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.createVisit(visitData)

      const createdVisit = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: CREATE_VISIT_SUCCESS,
        payload: createdVisit.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: CREATE_VISIT_FAILURE,
        payload: error,
      })
    }
  }
}

export const updateVisit = (id, visitUpdates) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_VISIT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.updateVisit(id, visitUpdates)

      const updatedVisit = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: UPDATE_VISIT_SUCCESS,
        payload: updatedVisit.data,
      })
      return 'updated'
    } catch (error) {
      // console.log(error);
      dispatch({
        type: UPDATE_VISIT_FAILURE,
        payload: error,
      })
    }
  }
}

export const deleteVisit = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_VISIT_REQUEST,
    })
    try {
      const { url, method, headers, data } = api.deleteVisit(id)

      const deletedVisit = await axios({
        url,
        method,
        headers,
        data,
      })
      dispatch({
        type: DELETE_VISIT_SUCCESS,
        payload: deletedVisit.data,
      })
    } catch (error) {
      // console.log(error);
      dispatch({
        type: DELETE_VISIT_FAILURE,
        payload: error,
      })
    }
  }
}

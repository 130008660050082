import axios from 'axios'
import api from '../api/index'

const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST'
const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE'

const initialState = {
  loading: false,
  error: false,
  data: [],
}

export default function reducerProperties(state = initialState, action) {
  switch (action.type) {
    case GET_CITIES_REQUEST:
      return { ...state, loading: true, error: false }

    case GET_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }

    case GET_CITIES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload }

    default:
      return state
  }
}

export const getCities = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CITIES_REQUEST,
    })

    try {
      const { url, method, headers } = api.getCities()
      const result = await axios({
        url,
        method,
        headers,
      })
      dispatch({
        type: GET_CITIES_SUCCESS,
        payload: result.data,
      })
    } catch (error) {
      dispatch({
        type: GET_CITIES_FAILURE,
        payload: error,
      })
    }
  }
}
